<template>
<div>
    <div class="navbar-container d-flex content align-items-center">


      <!-- Left Col -->
      <div class="w-100 align-items-center justify-content-between  d-flex">
        <div>
          <b-avatar
            size="40"
            variant="light-primary"

            :src="require('@/assets/images/logo/logo.png')"
            class="badge-minimal"
          />
        </div>

        <div>

          <!-- <dark-Toggler class="d-none d-lg-block" /> -->
          <!-- <Locale /> -->

          <div class="d-flex align-items-center">
            <!-- <lanIcon /> -->

            <div class="mx-1">
              الغه العربية
            </div>
            <svg
              id="Group_11535"
              data-name="Group 11535"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
               >
              <path
                id="Path_66429"
                data-name="Path 66429"
                d="M0,0H24V24H0Z"
                fill="none"
              />
              <circle
                id="Ellipse_1445"
                data-name="Ellipse 1445"
                cx="9"
                cy="9"
                r="9"
                transform="translate(3 3)"
                fill="none"
                stroke="#272c30"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <line
                id="Line_39"
                data-name="Line 39"
                x2="16.8"
                transform="translate(3.6 9)"
                fill="none"
                stroke="#272c30"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <line
                id="Line_40"
                data-name="Line 40"
                x2="16.8"
                transform="translate(3.6 15)"
                fill="none"
                stroke="#272c30"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                id="Path_66430"
                data-name="Path 66430"
                d="M11.5,3a17,17,0,0,0,0,18"
                fill="none"
                stroke="#272c30"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                id="Path_66431"
                data-name="Path 66431"
                d="M12.5,3a17,17,0,0,1,0,18"
                fill="none"
                stroke="#272c30"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
            </svg>

          </div>
        </div>
      </div>

    </b-card></div>
</div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    // DarkToggler,
    Locale,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
